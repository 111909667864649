@import "../../assets/scss/variables.scss";

.full {
    margin: auto;   
    position: fixed;
    z-index:9999;    
    left:0;right: 0;
    width: 100%;
    display: flex;
    align-items: center;
    background: #1b191970;
    line-height: 27px;
div{
    margin:auto;
}
}
.btn-loader {
    margin: auto;
    position: relative;
    z-index: 9999;
    left: 0px;
    right: 0px;
    top: 0;
}
.full{
    position: fixed;
    bottom: 0%;
    right: 0%;
    width: 100%;
    height: 100%;
    z-index: 111;
}