@import "./assets/scss/variables.scss";
body {
  margin: 0px;
  padding: 0px;
  font-family: $ROBOTO-REGULAR !important;
  background: $BG !important;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: "Roboto-Light";
  src: url("./assets/fonts/Roboto-Light.woff2") format("woff2"),
    url("./assets/fonts/Roboto-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("./assets/fonts/Roboto-Medium.woff2") format("woff2"),
    url("./assets/fonts/Roboto-Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("./assets/fonts/Roboto-Regular.woff2") format("woff2"),
    url("./assets/fonts/Roboto-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("./assets/fonts/Roboto-Bold.woff2") format("woff2"),
    url("./assets/fonts/Roboto-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "qiot";
  src: url("./assets/fonts/qiot.eot?3c3e2o");
  src: url("./assets/fonts/qiot.eot?3c3e2o#iefix") format("embedded-opentype"),
    url("./assets/fonts/qiot.ttf?3c3e2o") format("truetype"),
    url("./assets/fonts/qiot.woff?3c3e2o") format("woff"),
    url("./assets/fonts/qiot.svg?3c3e2o#qiot") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "qiot" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-eye-off:before {
  content: "\e900";
}
.icon-eye-on:before {
  content: "\e901";
}
.icon-backarrow:before {
  content: "\e902";
}
.icon-lefttarrow:before {
  content: "\e903";
}
.icon-rightarrow:before {
  content: "\e904";
}
.icon-close:before {
  content: "\e905";
}
.icon-menu:before {
  content: "\e906";
}
.icon-loaction:before {
  content: "\e907";
}
.icon-alert:before {
  content: "\e908";
}

a {
  &:hover {
    text-decoration: none;
  }
}

// #downloadicon{
//   &.active{
//     display: none;
//   }
// }
.fitness {
  height: 64px !important;
}
#downloadlabel {
  opacity: 1;
  color: #fff;
}

p {
  margin: 0;
}
.moreOptions {
  position: absolute;
  top: 50px;
  right: 50px;
  background: $BG_WHITE;
  border-radius: 4px;
  width: 90px;
  -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  z-index: 1;
  ul {
    margin: 0px !important;
    padding: 0px !important;
    list-style: none;
    border: none !important;
    display: inline-block !important;
    width: 100%;
    float: left;
    li {
      padding: 0px !important;
      margin: 0px !important;
      float: left !important;
      width: 100% !important;
      min-height: auto !important;
      &::before {
        display: none !important;
      }

      &:last-child {
        a {
          border-bottom: none;
          color: $TXT_RED !important;
          text-align: center !important;
        }
      }
      a {
        font-size: 14px;
        line-height: 20px;
        color: Grey !important;
        cursor: pointer;
        width: 100%;
        float: left;
        padding: 6px 0;
        font-weight: normal;
        border-bottom: 1px solid Grey;
      }
    }
  }
}
.bg-Overlay {
  background: red;
  width: 100%;
  position: absolute;
  z-index: 1;
  height: 100%;
  opacity: 0;
  &.on_Hide {
    display: none;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
