// Border Color
$INPUTBORDER: #cfdbe6;
$BORDER: #c4c4c4;
$INPUTREDBORDER: #ee2737;
$CHECKBOXBORDER: #707070;
$BUTTONBORDERCOLOR: #b8b8b8;

// Qiot

$TRANSPARENT: transparent;
$BG_LIGHT: #f2faff;
$OFF_LIGHT: #f2f6f9;
$BG: #f9f9fb;
$BRAND: #004283;

$BG_WHITE: #ffffff;
$BG: #f9f9fb;
$BG_BRAND: #004283;
$BG_DARKGREY: #6c6c6c;
$BG_GREY: #ededed;
$BG_BLUEOPACITY: #e5ecf3;
$BG_GREEN: #27ae60;
$BG_LIGHTORANGE: #fff3e5;
$BG_GREENOPT: #e9f7ef;
$BG_ORANGE: #ff8500;
$BG_BLUELIGHT: #e7f4fe;
$BG_REDLIGHT: #ffecec;
$BG_RED: #fd4646;
$BG_BLUE: #0d8ef8;
$BG_DARKRED: #d10000;

// Color
$TXT_WHITE: #ffffff;
$TXT_BLUE: #004283;
$TXT_LIGHTBLUE: #0d8ef8;
$TXT_DARKBLUE: #0f4c81;
$TXT_GREY: #a6a6a6;
$TXT_LIGHTGREY: #707070;
$TXT_DARKGREY: #6c6c6c;
$TXT_RED: #fd4646;
$TXT_ORANGE: #ff8500;
$TXT_YELLOW: #f2c94c;
$TXT_GREEN: #27ae60;
$TXT_INPUT: #495057;
$TXT_DARKRED: #d10000;
$BORDER: #91d7ff;
$BORDERLIGHT: #d8e2ec;
// $BORDERLIGHT:#DAE4ED;

$BOX_SHADOW: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
// Font Size
$FONT_10: 10px;
$FONT_12: 12px;
$FONT_14: 14px;
$FONT_16: 16px;
$FONT_18: 18px;
$FONT_20: 20px;
$FONT_22: 22px;
$FONT_24: 24px;
$FONT_26: 26px;
$FONT_28: 28px;
$FONT_30: 30px;
$FONT_32: 32px;
$FONT_48: 48px;

// Font Family

// Qiot Font Family

$ROBOTO-LIGHT: "Roboto-Light";
$ROBOTO-REGULAR: "Roboto-Regular";
$ROBOTO-MEDIUM: "Roboto-Medium";
$ROBOTO-BOLD: "Roboto-Bold";
