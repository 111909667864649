@import "../../../assets/scss/variables.scss";

.toast {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	max-width: 100% !important;
	z-index: 999;
	text-align: center;
	flex-basis: inherit !important;
	margin: 0 auto !important;
	border: 0 !important;
	border-radius: 0 !important;
	box-shadow: none !important;
	background-color: transparent !important;
	padding: 10px 0;

	.tostcenter {
		display: inline-block;
		min-width: 375px;
		// width: 100%;
		border-radius: 8px;
		padding: 12px 10px;
		text-align: center;
		margin: 0 auto;
		background-color: $BG_WHITE;
		-webkit-box-shadow:  0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
		-moz-box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
		box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);

		p {
			margin: 0px;
			font-family: $ROBOTO-REGULAR;
			font-size: $FONT_16;
			line-height: 24px;
		}
	}
	&.show {
		opacity: 1;
	}

	&.success-txt {
		.tostcenter {
			p {
				color: $TXT_BLUE;
			}
		}
	}
	&.error-txt {
		.tostcenter {
			p {
				color: $TXT_RED;
			}
		}
	}
	&.warning-txt {
		.tostcenter {
			p {
				color: $TXT_RED;
			}
		}
	}
}
